import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import ShopifyServiceBanner from '../images/shopify_service_banner.svg'
import ShopifyServiceFeature from '../images/shopify_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const ShopifyDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/shopify-web-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Shopify Web Development Services" description='eWise - Shopify Web Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Shopify Web </strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-500 lg:max-w-530 m-auto lazyload" loading="lazy" data-src={ShopifyServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Enhance your customers' experience with an online <br class="hidden xl:inline-block"></br>store!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p className="pb-4">An Ecommerce store that is quick to setup and has seamless transitions with optimized user experience- that's Shopify for you!</p>
                <p>It is a streamlined platform that enables businesses to set up effective Ecommerce stores well-suited for every kind of business. The Ecommerce stores designed through Shopify are extremely simplified and user-friendly, which makes them more appealing to your website visitors.</p>

            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full px-4 flex flex-wrap">
                    
                    <div className="w-full lg:w-1/2">
                       
                        <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise suggests Shopify with Distinctive Features<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
    
                        <div className="features_list_left w-full pb-10 md:pb-12 lg:pb-0">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">A customized storefront</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">With 70+ themes for various kinds of business.</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Web-based builder for convenient development of the storefront</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Mobile commerce readily available with the platform</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Easy customization with user-friendly HTML and CSS</li>
                                <li className="pl-10 relative">Publish your blogs, magazines and articles</li>
                            </ul>
                        </div>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4">
                    <img className="object-contain max-w-330 md:max-w-470 xl:max-w-610 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={ShopifyServiceFeature} alt="iphone"/>
                </div>
                <div className="-mx-4">
                    <div className="w-full flex-wrap flex pt-0 lg:pt-6">
                        <div className="features_list_left w-full md:w-1/2 pb-6 px-4">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Flexible shopping cart options</h5>
                            <ul className="list_features">
                                <li className=" pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">100+ payment gateway options to your customers</li>
                                <li className=" pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Free 256 bit SSL certificate</li>
                                <li className=" pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Charge shipping according to weight, size or distance</li>
                                <li className=" pl-10 relative">Automated email to visitors who abandoned cart</li>
                            </ul>
                        </div>
        
                        <div className="features_list_left w-full md:w-1/2 pb-6 px-4">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Easy store management</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Manage your shop with a simple Shopify mobile application</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Make your order fulfillment hassle-free with one-click</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Categorize the similar location, similar purchase orders</li>
                                <li className="pl-10 relative">Automate emails with ready-made email templates</li>
                            </ul>
                        </div>
                        <div className="features_list_left w-full md:w-1/2 pb-6 px-4">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Enhanced marketing and SEO</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Enable browsing leaving social media page</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Generate different discount codes</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Space for customers to review products</li>
                                <li className="pl-10 relative">SEO supporting framework</li>
                            </ul>
                        </div>
                        <div className="features_list_left w-full md:w-1/2 pb-6 px-4">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Easy product management</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Track and organize your products by category, type, season, sale, and more</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Notifies if products out of stock automatically</li>
                                <li className="pl-10 relative">Display multiple images of one product similar</li>
                            </ul>
                        </div>
                        <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Gain insights</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Keep the continuous and real-time track of your sales</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Analyze which product is performing better</li>
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Analyze the website traffic</li>
                                <li className="pl-10 relative">Export analytics report to your devices</li>
                            </ul>
                        </div>
                        <div className="features_list_left w-full md:w-1/2 px-4">
                            <h5 className="pb-4 text-lg md:text-xl lg:text-22">Mobile application</h5>
                            <ul className="list_features">
                                <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Mobile application without paying anything extra</li>
                                <li className="pl-10 relative">Easy and user-friendly access to storefronts with a responsive mobile</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">What all can be done with Shopify<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="pb-4 px-4">Shopify is an enterprise level platform with which eWise enables you to transform your business identity on the web world. From building an Ecommerce portal from scratch to switching from a dated technology, everything can be done with much ease and cost-effectiveness with Shopify.</p>
                    <ul className="list_features px-4">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Shopify is a perfect opportunity to give a boost to that long pending business idea. Now build your online business platform from scratch, so you can focus on more important things in it.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">With an online store, you can be assured of the business flowing from every part of the world. It will not only increase the trust factor of customers, but also enhance your revenues.</li>
                        <li className="pl-10 relative">Shopify is an enterprise-grade platform with functionalities that make Ecommerce stores build on it extremely user-friendly and also trendy.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Hiring eWise as a Shopify expert<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="pb-4 px-4">Now that you have made the decision to choose this platform to build your Ecommerce portal let us see why you should hire an expert to do it for you!</p>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Store Setup</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">At eWise, our Shopify professionals always develop a customized theme or make customizations to the existing theme, so that it represents your brand the way you want.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Theme Development and customization</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise understands that it is important to tailor the theme to your business. We make sure to develop and customize your online portal that is 100% unique!</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Shopify Custom Functionality Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise enables you to build a Shopify platform that is easily scalable to the growing business needs, making it quite a flexible and cost-effective Ecommerce setup solution.</li>
                    </ul>
                </div>
            </div>

            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as Shopify expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">eWise as a Shopify website developer expert can guide you to build an extensive Ecommerce platform that is engaging and interactive to your visitors. Our skilled and experienced teams will make sure that your online storefront enables you to achieve your optimum sales targets and business goals. For any query on Shopify or Ecommerce platforms, you can connect with us.</p>
                    <p className="px-4">We promise to deliver you the most unique and exciting online storefronts!</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default ShopifyDevelopment